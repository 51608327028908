.App {
  text-align: left;
}

.App-logo {
  height: 50px;
  padding: 8px 20px;
  pointer-events: none;
}

.App-header {
  background: linear-gradient(#3a3d51, #000e2e);
  min-height: 3vh;
  color: white;
}

.App-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  max-width: 1440px;
  margin: auto;
}

.App-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 8px;
}

.pure-menu-link {
  display: flex;
  height: 55px;
  color: #ffffff;
  align-items: center;
  font-weight: bold;
}

.pure-menu-link.active,
.pure-menu-link:active,
.pure-menu-link:focus {
  background: linear-gradient(#eef0f3, #f2f2f2);
  color: #000e2e;
}

.pure-menu-link:hover {
  background: linear-gradient(#ff2631, #d90429);
  color: white;
}

.App-link {
  color: #61dafb;
}

button.pure-button.pure-button-danger {
  background-color: #ff4f43;
  color: white;
}

aside {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding: 1em;
}
aside.info-block {
  background-color: #f5f8ff;
  border: 1px solid cornflowerblue;
}
aside.warn-block {
  background-color: #fff9e3;
  border: 1px solid rgb(237, 216, 100);
}

pre.list {
  border: none;
  font-size: smaller;
}
.status {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: small;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ready-label,
.claimed-label {
  color: white;
  display: block;
  width: 7em;
  text-align: center;
  border-radius: 4px 4px 0 0;
  height: 18px;
  line-height: 22px;
  font-weight: bold;
}


.ready-label {
  background: #96b696;
 }

 .claimed-label {
  background: #ff4f43;
  }  

.ready-border {
  border: 5px solid #96b696;
}

.claimed-border {
  border: 5px solid #ff4f43;
}

.technical {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
  font-size: smaller !important;
  background: linear-gradient(#3a3d51, #000e2e);
  color: white;
}
.technical.strong,
.technical.label {
  color: white;
}

.technical label {
  color: #cbffee;
}
.technical input,
.technical textarea {
  background-color: #3e4258;
  border: 1px solid #99b7ad !important;
  box-shadow: inset 0 1px 3px #1a1919 !important;
}
.technical .technical .pure-form-message-inline {
  color: #8eb5a8;
}

.technical label.inline {
  display: inline;
  font-weight: bold;
}

.technical input[type=color][disabled], .technical input[type=date][disabled], .technical input[type=datetime-local][disabled], .technical input[type=datetime][disabled], .technical input[type=email][disabled], .technical input[type=month][disabled], .technical input[type=number][disabled], .technical input[type=password][disabled], .technical input[type=search][disabled], .technical input[type=tel][disabled], .technical input[type=text][disabled], .technical input[type=time][disabled], .technical input[type=url][disabled], .technical input[type=week][disabled], .technical select[disabled], .technical textarea[disabled] {
  cursor: not-allowed;
  background-color: #c1d2d2;
  color: #697b7d;
}